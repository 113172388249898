import { v4 as uuidv4 } from 'uuid';

// 获取视频基本信息
const getVideoBasicInfo = (videoSrc) =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = videoSrc;
    // 视频一定要添加预加载
    video.preload = 'auto';
    // 视频一定要同源或者必须允许跨域
    video.crossOrigin = 'Anonymous';
    // 监听：异常
    video.addEventListener('error', (error) => {
      reject(error);
    });
    // 监听：加载完成基本信息,设置要播放的时常
    video.addEventListener('loadedmetadata', () => {
      // console.log(video.duration, '======duration');
      const videoInfo = {
        video,
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration > 2 ? 2 : video.duration, //只加载前面2秒
      };
      resolve(videoInfo);
    });
  });
// 将获取到的视频信息，转化为图片地址
const getVideoPosterInfo = (videoInfo): any =>
  new Promise((resolve) => {
    const { video, width, height } = videoInfo;
    video.addEventListener('canplay', () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, width, height);
      const posterUrl = canvas.toDataURL('image/jpg');
      resolve({ posterUrl });
    });
  });
//获取处理后的图片地址
const getImgUrl = async (videoSrc) => {
  const videoInfo = await getVideoBasicInfo(videoSrc);
  const { posterUrl } = await getVideoPosterInfo(videoInfo);
  return await base64ToFile(posterUrl);
};
export default getImgUrl;

async function base64ToFile(base64Data) {
  const response = await fetch(base64Data);
  const blob = await response.blob();
  return new File([blob], `${uuidv4()}.jpg`, { type: 'image/jpg' });
}
