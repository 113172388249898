export const API = {
  // 查询主播
  queryAnchorPage: '/quan/biz/anchor/list/page',
  // 新增主播
  saveAnchor: '/quan/biz/anchor/add',
  // 编辑主播
  editAnchor: '/quan/biz/anchor/edit',
  // 删除主播
  deleteAnchor: '/quan/biz/anchor/delete',
  // 群组列表
  queryGroupList: '/quan/biz_social_group/applet/group/list',
};
