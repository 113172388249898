import type Model from '../../model';
import { request } from '../../../../utils';
import type { BaseData } from '../../../../utils';
import type { FormInstance, UploadFile, DescriptionsProps } from 'antd';
import { message } from 'antd';
import SelectGroupModel from '../selectGroup/model';
import { action, computed, observable } from 'mobx';
import { API } from '../../api';
import _ from 'lodash';
import getImgUrl from '../../../../utils/firstFrame';

interface IGroup {
  anchorGroupId: number;
  anchorGroupName: string;
  anchorGroupNickName: string;
  anchorId?: number;
}

interface IOpus {
  anchorId?: number;
  id?: number;
  worksType: string;
  worksUrl: string;
}

export default class AnchorEditModel {
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
  }

  public parentStore: Model;

  @observable public visible = false;

  @observable public anchorId;

  @observable public ref: FormInstance;

  @observable public saveLoading = false;

  @observable public groupInfo: IGroup[] = []; // 群组列表

  @observable public anchorInfo: DescriptionsProps['items'] = [];

  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @observable public opusListVideo = [];

  @observable public opusListImage = [];

  // 打开弹窗
  @action public onShow = async (data?: any) => {
    this.visible = !this.visible;
    if (data) {
      this.anchorId = data.id;
      const req = await request<BaseData<any>>({
        method: 'GET',
        url: '/quan/biz/anchor/detail',
        params: { id: data.id },
      });
      this.handleAnchorData(req.data);
      this.groupInfo = req.data?.groupInfo || [];
      this.opusListImage = (req.data?.children || [])
        ?.filter((item) => item.worksType === '0')
        ?.map((item) => ({
          uid: _.uniqueId('opusListImage_'),
          status: 'done',
          url: item.worksUrl,
        }));
      this.opusListVideo = await Promise.all(
        (req.data?.children || [])
          ?.filter((item) => item.worksType === '1')
          ?.map((item) => ({
            uid: _.uniqueId('opusListImage_'),
            status: 'done',
            url: item.worksUrl,
            thumbUrl: item.coverUrl,
          })),
      );
    }
  };

  // 关闭弹窗
  @action public onCancel = () => {
    this.visible = false;
    this.groupInfo = [];
    this.anchorId = undefined;
    this.opusListImage = [];
    this.opusListVideo = [];
    this.anchorInfo = [];
  };

  // 添加群组
  @action public addGroup = (group: any) => {
    if (!group) {
      return;
    }
    this.groupInfo.push(group);
  };

  // 预览视频作品
  public previewVideoOpus = (videoUrl: string) => {
    console.log(videoUrl);
  };

  // 处理主播数据展示
  @action public handleAnchorData = (data) => {
    const descriptionsData = {
      name: '姓名',
      birthday: '出生日期',
      sex: '性别',
      height: '身高',
      weight: '体重',
      anchorGroupNickName: '微信群昵称',
      seniority: '工作年限',
      highestGmv: '单场最高GMV',
      skilledField: '擅长领域',
      mobile: '手机号',
      wechatCode: '微信号',
    };
    for (const key in descriptionsData) {
      if (Object.prototype.hasOwnProperty.call(descriptionsData, key)) {
        this.anchorInfo.push({
          key,
          label: descriptionsData[key],
          children: this.dataValueFormat(key, data),
        });
      }
    }
  };

  //主播数据值格式化
  private dataValueFormat = (key, data) => {
    switch (key) {
      case 'sex':
        return data[key] ? '男' : '女';
      case 'height':
        return `${data[key]}CM`;
      case 'weight':
        return `${data[key]}KG`;
      case 'seniority':
        return `${data[key]}年`;
      case 'highestGmv':
        return `${data[key]}元`;
      case 'anchorGroupNickName':
        return data.groupInfo?.length ? data.groupInfo[0]?.anchorGroupNickName : '';
      default:
        return data[key] || '';
    }
  };
}
